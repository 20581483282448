html {
  font-size: 87.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: proxima-nova, source-han-sans-japanese, sans-serif;
  font-weight: normal;
  line-height: 1;
  color: #313233;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
