.App {
  text-align: center;
  max-width: 455px;
  margin: 30px auto 100px;
}

.App-logo {
  height: 50px;
}

.App-header {
  margin-top: 50px;
  margin-bottom: 30px;
}
