.Login {
  margin: 0 20px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 2px rgb(46 41 51 / 12%), 0 2px 9px rgb(71 63 79 / 18%);
}

.Login-error {
  padding: 14px 10px;
  margin-top: 20px;
  background-color: #ffeef0;
  display: none;
}

.Login-error.is-error {
  display: block;
}

.Login-inputs {
  margin-bottom: 20px;
  text-align: left;
}

.Login-label {
  display: block;
  margin-bottom: 5px;
  color: #606161;
}

.Login-input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.57143rem;
  box-sizing: border-box;
  border-radius: 0.14286rem;
  border: solid 0.07143rem #d5dce5;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 20px;
  box-shadow: inset 0 1px 2px rgb(67 67 67 / 10%);
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

.Login-input:focus {
  border-color: rgba(2, 140, 255, 0.65);
  box-shadow: 0 0 1px rgba(2, 140, 255, 0.65);
  outline: none;
}

.Login-btn {
  display: block;
  text-align: center;
  width: 100%;
  height: 40px;
  background-color: #1b6bd4;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  border: none;
}

.Login-btn:hover {
  cursor: pointer;
}
